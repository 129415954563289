import React from 'react'
import './media.scss'
import {
  businessLine,
  firstPost,
  drinksReport,
  mediaNama,
  yourStory
} from './../../images'
import Slider from "react-slick"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const Media = () => (
  <div id="media">
    <div className="container media-news">
      <h2>Media</h2>
      <div className="media-items">
        <Slider {...settings}>
          <div className="col">
            <p>
              “A mobile app that moves the bar to the cloud and promotes responsible drinking.”
            </p>
            <a target="_blank" href="https://www.firstpost.com/living/hipbar-a-mobile-app-that-moves-the-bar-to-the-cloud-and-promotes-responsible-drinking-3877031.html">Read More</a>
            <div>
              <img src={firstPost} />
            </div>
          </div>

          <div className="col">
            <p>
              “HipBar’s mobile app, which was launched first in Bengaluru, enables online purchase by partnering with leading spirit brands, stores.”
            </p>
            <a target="_blank" href="https://www.thehindubusinessline.com/companies/startup-hipbar-drinks-to-highway-liquor-ban/article9627760.ece">Read More</a>
            <div>
              <img src={businessLine} />
            </div>
          </div>

          {/* <div className="col">
            <p>
              “A mobile app that moves
              the bar to the cloud
              and promotes responsible drinking”
            </p>
            <a href="https://techstory.in/hipbar/">Read More</a>
            <div>
              <img src={TechStory} />
            </div>
          </div> */}

          <div className="col">
            <p>
              “HipBar consumers can experience the privileges of pre-paid – value, convenience, priority access, predictable experience, and hassle-free checkout.”
            </p>
            <a target="_blank" href="http://www.thedrinksreport.com/interviews/2017/166-prasanna-natarajan.html">Read More</a>
            <div>
              <img src={drinksReport} />
            </div>
          </div>

          <div className="col">
            <p>
              “RBI gives a wallet licence to a company which only allows you to pay for drinks and not buy drinks”
            </p>
            <a target="_blank" href="https://www.medianama.com/2017/04/223-hip-bar-rbi-wallet-licence/">Read More</a>
            <div>
              <img src={mediaNama} />
            </div>
          </div>

          <div className="col">
            <p>
              “HipBar helps you discover your favourite drink and the place you want to redeem it at.”
            </p>
            <a target="_blank" href="https://yourstory.com/2017/03/hipbar-startup/">Read More</a>
            <div>
              <img src={yourStory} />
            </div>
          </div>


        </Slider>
      </div>
    </div>
  </div>
)

export default Media
