import React from 'react'
import './../../sass/home.scss'
import HeroContainer from './../../components/hero-container'
import Media from './../../components/media'
import Navbar from './../../components/navbar'
import Icon from "./../../components/icon"
import {
  home,
  hipbarAppHero,
  hipbarApp,
  hipbarLogo,
  googlePlayBtn,
  appStoreBtn,
  arrowForward,
  hipbarNewApp
} from './../../images'
import DeliveryPopUp from './../../components/delivery-popup'
import {mountModal, unmountModal} from './../../components/delivery-popup/utils'

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem("displayPopUp", "visited")
    }
    unmountModal()
  } 

  renderPopup () {
    return mountModal(
      DeliveryPopUp({
        actions: 
          [
          <div onClick={this.handleClose}>
            <Icon name="closeButton" />
          </div>
          ]
        })
      )
  }
  
  render () {
    return (
      <div id="home">
        <Navbar currentRoute={this.props.currentRoute} history={this.props.history} />
        <HeroContainer bgImage={home}>
          <div className="hero-content">
            <div className="left">
              <h1>Cheers to a Digital future!</h1>
              <p>
                Founded with a vision to create a safe, transparent and responsible digital ecosystem, HipBar is here to help India drink wiser, one drink at a time.
              </p>
              {/* <div style={{ position: "relative", top: "100px" }}>
                <a style={{ cursor: "pointer" }} target="_blank" href="https://gifting.hipbar.com">
                  <button style={{
                    cursor: "pointer",
                    fontFamily: "Montserrat",
                    border: "1px solid #fff",
                    borderRadius: "5px",
                    color: "#000",
                    fontWeight: "600",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                    background: "#fff",
                    padding: "0px 30px",
                    height: "42px",
                    width: "180px"
                  }}>
                    Gift drinks
                  </button>
                </a>
              </div> */}
            </div>
            <div className="right">
              <img src={hipbarAppHero} />
            </div>
          </div>
        </HeroContainer>

        {
          typeof window !== "undefined" && window.sessionStorage.getItem("displayPopUp") !== "visited" &&
          this.renderPopup()
        }

        <div className="content">
          <div className="container hipbar-about">
            <p>
              ✔ Here to make a difference. To help India drink wiser.<br /><br />
              ✔ Here to lead a digital transformation. Of the Industry, Of the Buying experience.<br /><br />
              ✔ Here to foster a safe & responsible drinking environment.<br /><br />

              {/* HipBar is a RBI-authorized mobile wallet and India's first digital currency exclusively meant for transacting adult beverages. */}
          </p>
            <a className="outline-outward" href="/about">Read More <img src={arrowForward} /></a>
          </div>


          <div className="container hipbar-delivery apps">
            <div className="left">
              <img src={hipbarNewApp} />
            </div>
            <div className="right">
              <img className="logo" src={hipbarLogo} />
              <h1 style={{ color: '#e97c07' }}>HipBar</h1>
              <p>
                Get your favourite drinks home delivered
              </p>
              <ul>
                <li>
                  <span>Pick a store</span> - Turn on your location & select a store near you
                </li>
                <li>
                  <span>Pick a drink</span> - Browse the store's inventory & select your drink
                </li>
                {/* <li>
                  <span>Gift friends</span> - For the first time, you can gift drinks to friends in just a few swipes!
                </li> */}
                <li>
                  <span>Pay</span> - Pay securely & place your order
                </li>
                <li>
                  <span>Done!</span> - Our agent will bring home your drinks. Keep your ID ready!
                </li>
              </ul>

              <div className="app-btns">
                <a target="_blank" href="https://hipbar.onelink.me/X8lF/HBWebsiteBadgeSep2"><img src={googlePlayBtn} /></a>
                <a target="_blank" href="https://hipbar.onelink.me/0DEk/HBWebsiteBadgeSep2"><img src={appStoreBtn} /></a>
              </div>
            </div>
          </div>

          {/* <div className='container hipbar-pay apps'>
            <div className='left'>
              <img src={hipbarPay} />
            </div>
            <div className='right'>
              <img className='logo' src={hipbarPayLogo} />
              <h1>HipBar Pay</h1>
              <p>
                Pay digitally at TASMAC mall outlets with HipBar Pay
              </p>
              <ul>
                <li>
                  <span>Fast</span> - Load money, enter the value of the product and
                  show the QR code to get your drink. It’s that simple!
                </li>
                <li>
                  <span>Convenient</span> - No cash. No problem. All you need is HipBar Pay.
                </li>
                <li>
                  <span>Secure - </span>
                  HipBar Pay is a safe and secure way to pay for the beverage of your choice.
                </li>
              </ul>

              <div className='app-btns'>
                <a target='_blank' href='https://play.google.com/store/apps/details?id=in.hipbar.hipbar_pay_app&hl=en_IN'><img src={googlePlayBtn} /></a>
                <a target='_blank' href='https://itunes.apple.com/in/app/hipbar-pay/id1297218847?mt=8'><img src={appStoreBtn} /></a>
              </div>
            </div>
          </div> */}

          <hr />
          {/* <Media /> */}

        </div>
      </div >
    )
  }
}


export default Home
