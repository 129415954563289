import React from 'react'
import './../../sass/resources.scss'
import HeroContainer from './../../components/hero-container'
import Media from './../../components/media'
import Navbar from './../../components/navbar'

import {
  caseStudyBengaluru,
  caseStudyChennai,
  resources,
  blog
} from './../../images'


class Resources extends React.Component {
  render () {
    return (
      <div id="resources">
        <Navbar currentRoute={this.props.currentRoute} history={this.props.history} />
        <HeroContainer bgImage={resources}>
          <div className="hero-content">
            <h1>Resources</h1>
          </div>
        </HeroContainer>

        <div className="content">
          {/* <div className="container about">
            <h2>
              Built on the latest technology,
              our microservice architecture is similar to that
              of Google, Facebook, Netflix, Uber & Twitter
              among others and draws on the best tech
              practices of the giants.
            </h2>
          </div> */}

          <div className="container case-studies">
            <h2>Case Studies</h2>
            <div className="col">
              <a href="/case-study/chennai">
                <div className="case-studies-item">
                  <img src={caseStudyChennai} />
                  <div className="case-studies-content">
                    <p>MRP Issue in Chennai</p>
                    <p>
                      HipBar’s vision is to lead the
                      digital transformation of the
                      industry.
                    </p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col">
              <a href="/case-study/banglore">
                <div className="case-studies-item">
                  <img src={caseStudyBengaluru} />
                  <div className="case-studies-content">
                    <p>HipBar Delivery in Bengaluru</p>
                    <p>
                      HipBar’s vision is to lead the
                      digital transformation of the
                      industry.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="container blogs">
            <h2>Blog</h2>
            <div className="col">
              <img src={blog} />
            </div>
            <div className="col">
              <h3>If it has anything to do with drinks, it'll be here!</h3>
              <p>
                Facts, jokes, trivia, party hacks, hangover cures, wine pairing tips, drinking etiquette and more! We want to create a knowledgeable drinking community and share a few laughs along the way.
              </p>
              <a target="_blank" href="https://medium.com/the-hipbar-blog">Read more</a>
            </div>
          </div>

          <hr />
          <Media />

          {/* <div className="container press-kit">
            <div className="col">
              <img src={faqImg}/>
              <div>
                <p>FAQs</p>
                <p>Frequently asked quesitons</p>
                <a target="_blank" href="/faq">Click here</a>
              </div>
            </div>

            <div className="col">
              <img src={pressKitImg}/>
              <div>
                <p>Press Kit</p>
                <p>View our press kit</p>
                <a target="_blank" href="/HipBarPressKit.pdf">Click here</a>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    )
  }
}


export default Resources
