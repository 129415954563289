import React from 'react';
import './popup.scss';
import Icon from './../icon';

export default function DeliveryPopUp(data) {
  return class DeliveryPopUp extends React.Component {
    render() {
      return (
        <div id="container" className="modal-overlay">
          <div className="popup-content">
            <div className="header">
              <div className="close-button">
                {data.actions}
              </div>
            </div>
            <div className="body warning-paragraph">
              <h2 className="warning-heading">IMPORTANT<br /> PUBLIC NOTICE</h2>
              <p className="warning-text">Hip Bar Pvt Ltd has been acquired by Dreamplug technologies Pvt Ltd [CRED] and renamed as Dreampurse Technologies Private Limited.</p>
              <p className="warning-text">If you hold a Hip Bar wallet, you can claim a refund of  the residual balance through your existing Hip Bar app or by filling <a href="https://zfrmz.in/10ASsFdqjAnCiBMpakMX" target="_blank">the form.</a></p>
              <p className="warning-text">For any questions or further assistance, reach out to us at support@hipbar.com / support@dreampurse.in</p>
            </div>
          </div>
            {/* <div className="header">
              <div className="close-button">
                {data.actions}
              </div>
            </div>
            <div className="row-0">
              <div className="warning-image">
                <img src="images/Warning-Icon.png" />
              </div>
            </div>
            <div className="row-1">
              <p>IMPORTANT PUBLIC NOTICE</p>
            </div>
            <div className="row-2">
              <p>
                Some individuals are advertising for HipBar franchises and collecting fees for the same.
                This is absolutely illegal and false as HipBar does not operate/plan to operate any franchise model.
              </p>
            </div>
            <div className="row-3">
              <p>Please report such cases to our team</p>
              <p>(support@hipbar.com or via in-app chat)</p>
            </div>
            <div className="row-4">
              <p>Stay safe. Stay alert.</p>
            </div>
            <div className="hipbar-logo">
              <Icon name="hipbarLogoWithName" />
            </div>
          </div> */}
        </div>
      )
    }
  }
}
