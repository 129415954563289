import React from 'react'
import './navbar.scss'
import { hipbarLogo, menu, cross } from './../../images'

// const navItems = ['home', 'technology', 'business', 'blog', 'careers', 'contact']
const navItems = ['home', 'contact']

class Navbar extends React.Component {
  constructor () {
    super()
    this.state = {
      opacity: 0,
      boxShadow: false,
      navbarMobile: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.toggleNavbarMobile = this.toggleNavbarMobile.bind(this)
    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseOut = this.handleMouseOut.bind(this)
  }

  toggleNavbarMobile () {
    const { navbarMobile } = this.state
    this.setState({ navbarMobile: !navbarMobile })
  }

  componentDidMount () {
    window.addEventListener('scroll', (e) => {
      const opacity = window.scrollY / 200
      this.setState({ opacity })
      if (opacity >= 1) {
        this.setState({ boxShadow: true })
      } else {
        this.setState({ boxShadow: false })
      }
    })
    this.setState({ currentRoute: location.pathname.slice(1) })
    this.links = document.querySelectorAll('.nav-items a')
  }

  handleMouseOver (e) {
    this.links.forEach(link => {
      link.style.opacity = 0.6
      e.target.style.opacity = 1
    })
  }

  handleMouseOut (e) {
    this.links.forEach(link => {
      link.style.opacity = 1
    })
  }

  handleClick (e) {
    const path = '/' + e.target.href.split('/')[3]
    e.preventDefault()
    this.props.history.push(path)
    this.setState({ navbarMobile: false })
    window.scroll(0, 0)
  }

  render () {
    return (
      <div
        style={{
          boxShadow: this.state.boxShadow ? '0 1px 1px 0 rgba(0, 0, 0, 1)' : '',
          backgroundColor: `rgba(0, 0, 0, ${this.state.opacity})`
        }}
        className="navbar">
        <div className="logo">
          <a onClick={this.handleClick} href="/home">
            <img src={hipbarLogo} />
          </a>
        </div>
        <div className="nav-items">

          {
            navItems.map((item, i) => {
              let link = item === 'blog' ? 'https://medium.com/the-hipbar-blog' : '/' + item
              return (
                <a
                  key={i}
                  onMouseOut={this.handleMouseOut}
                  onMouseOver={this.handleMouseOver}
                  className={`nav-item ${this.state.currentRoute === item ? 'active' : ''}`}
                  target={item === 'blog' ? '_blank' : ''}
                  onClick={item !== 'blog' ? this.handleClick : undefined}
                  href={link}
                >
                  {item}
                </a>
              )
            })
          }
        </div>

        <div className="navbar-mobile-toggle">
          {
            !this.state.navbarMobile
              ? <img src={menu} onClick={this.toggleNavbarMobile} />
              : <img src={cross} onClick={this.toggleNavbarMobile} />
          }
        </div>

        <div className={`navbar-mobile ${this.state.navbarMobile ? 'show' : 'hide'}`}>
          <ul>
            {
              navItems.map((item, i) => {
                let link = item === 'blog' ? 'https://medium.com/the-hipbar-blog' : '/' + item
                return (
                  <li key={i}>
                    <a
                      className={`${this.state.currentRoute === item ? 'active' : ''}`}
                      onClick={item !== 'blog' ? this.handleClick : undefined} href={link}>{item}
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default Navbar
