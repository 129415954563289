import React from 'react'
import Helmet from 'react-helmet'
import './../../sass/welcome.scss'
import HeroContainer from './../../components/hero-container'
import {
  ageGate,
  hipbarLogo,
  arrowForwardBlack
} from './../../images'

class Welcome extends React.Component {
  constructor () {
    super()
    this.state = {
      age: '',
      errorMessage: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleCheckAge = this.handleCheckAge.bind(this)
    this.handlePress = this.handlePress.bind(this)
  }

  componentDidMount () {
    localStorage.clear()
  }

  handleChange (e) {
    if (e.target.value.length == 4 && e.target.value <= '1900' ){
      this.setState({ errorMessage: 'Invalid Year' })
    }
    else 
     this.setState({ age: e.target.value, errorMessage: '' })
  }

  handlePress (e) {
    if (e.keyCode === 13) {
      this.handleCheckAge()
    }
  }

  handleCheckAge () {
    if (this.state.age.length < 4) return;
    const age = new Date().getFullYear() - parseInt(this.state.age)
    if (age < 21 || isNaN(age)) {
      this.setState({ errorMessage: 'Sorry, your age does not permit you to enter at this time' })
      return;
    }
    localStorage.setItem("age", age)
    location.href = '/home'
  }

  render () {
    return (
      <div id="welcome">
        <Helmet>
          <title>Welcome to HipBar</title>
        </Helmet>
        <HeroContainer bgImage={ageGate}>
          <div className="hero-content">
            <img src={hipbarLogo} />
            <h1 style={{ color: '#e97c07' }}>Welcome to HipBar</h1>
            <p>
              ID please. For people over a certain age,
this will be a compliment.
            </p>
            <div style={{ borderRadius: '4px', display: 'inline-block', marginTop: '20px', border: '2px solid #fff' }}>
              <div style={{ display: 'flex' }}>
                <input
                  value={this.state.age}
                  onKeyDown={this.handlePress}
                  onChange={this.handleChange}
                  maxLength={4}
                  placeholder="YYYY"

                 // pattern="^[1-9]\d*$"
                />
                <div style={{ display: 'inline-block' }}>
                  <a className="enter-site" onClick={this.handleCheckAge}>Enter<img src={arrowForwardBlack} /></a>
                </div>
              </div>
            </div>

            {this.state.errorMessage.length ? <p className="error">{this.state.errorMessage}</p> : ''}


            <div>
              <p style={{ marginTop: '40px', fontSize: '12px' }}>
                By entering this site, you are agreeing to our <a href="/user-terms">Terms & Conditions</a>, <a href="/privacy">Privacy Policy</a> and by continuing to browse this site you are agreeing to accept our use of cookies. HipBar endorses responsible and moderate drinking. Please share responsibly and do not forward this content on to anyone who is under the legal drinking age in their country of residence. HipBar ® Product of India. HipBar logo designs are trademarks owned by Hip Bar Pvt. Ltd. © 2019  All rights reserved. Enjoy responsibly.
              </p>
            </div>
          </div>
        </HeroContainer>
      </div>
    )
  }
}


export default Welcome
