import React from 'react'
import './../../sass/technology.scss'
import HeroContainer from './../../components/hero-container'
import Navbar from './../../components/navbar'
import {
  technology,
  scalability,
  indiaStack,
  compliance,
  security
} from './../../images'

const architectureItems = [
  {
    title: 'Scalability',
    description: `
    We are always several steps ahead strategy-wise. No matter how many users are on our platform at one
time, you can expect instant responses with zero downtime and no hang-ups. We have made sure that
all our services use cutting edge, open source standards, are cloud-native, highly scalable and backed by
Kubernetes.
    `,
    img: scalability
  },
  {
    title: 'Security',
    description: `
    As important as your information is for us to run our business smoothly, we recognize the fact that the
same data is priceless to you. Your data is safe with us and under no circumstance will any sensitive
information be shared with any external agency/company. We adhere to the industry’s best practices to
ensure your privacy is always protected from security breaches.
    `,
    img: security
  },
  {
    title: 'Compliance',
    description: `
    Our aim is to build a safe & responsible drinking environment in India. We are 100% compliant with extant laws & provisions & have approval from Excise Departments in states where we are operational.
    `,
    img: compliance
  },
  {
    title: 'India Stack',
    description: `
    In our mission to excel, we have made sure that our apps are in line with IndiaStack - a set of APIs that
allows governments, businesses, start-ups and developers to utilise a unique digital Infrastructure to
solve India’s difficulties towards establishing a presence-less, paperless, and cashless service delivery.
    `,
    img: indiaStack
  }
]

class Technology extends React.Component {
  render () {
    return (
      <div id="technology">
        <Navbar currentRoute={this.props.currentRoute} history={this.props.history} />
        <HeroContainer bgImage={technology}>
          <div className="hero-content">
            <h1>Technology</h1>
          </div>
        </HeroContainer>

        <div className="content">
          <div className="container about">
            <h2>
              Technology is the driving force behind HipBar. We go the extra mile to make sure our apps are built on
the best platforms to ensure the best experience for our customers. Built on state-of-the-art technology,
our microservice architecture draws from, and can be compared to that of technology giants like
Google, Facebook, Netflix, Uber and Twitter among others.<br /><br />
              We are a team of tech-enthusiasts, and understand that bad implementation of tech can cause several
              problems including security breaches, app crashes, and miscommunication between brands, retailers
              and customers. We value every customer and are constantly working to ensure they have a seamless
              experience on our apps. Our apps assure scalability, security, compliance accuracy &amp; a solid digital
              infrastructure based on the standards set by IndiaStack.
            </h2>

            <div className="architecture">
              {
                architectureItems.map((item, i) => (
                  <div key={i} className="architecture-item">
                    <img src={item.img} />
                    <p className="title">{item.title}</p>
                    <p className="description">
                      {item.description}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default Technology
