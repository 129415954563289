export const home = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557941724/Hipbar_Website/Home.jpg'
export const hipbarAppHero = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/q_auto:good/v1557940166/Hipbar_Website/Hero.png'
export const hipbarApp = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940156/Hipbar_Website/HB_The_drinks_app.png'
export const hipbarNewApp = 'https://res.cloudinary.com/www-hipbar-com/image/upload/v1598949332/HipBar_Web_Resources/hipbar.com/2020/A/2020-A_HipBar-DA-Screens_WB.png'
export const hipbarLogo = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940046/Hipbar_Website/01_HB_Logo.svg'
export const googlePlayBtn = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940083/Hipbar_Website/PlayStore.svg'
export const appStoreBtn = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940047/Hipbar_Website/app-store.svg'
export const arrowForward = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940048/Hipbar_Website/arrow-forward.svg'
export const arrowForwardBlack = 'https://res.cloudinary.com/www-hipbar-com/image/upload/v1557953151/Hipbar_Website/arrow-forward-white.svg'
//export const about = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940694/Hipbar_Website/About_Us.jpg'
export const praveen = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940127/Hipbar_Website/Praveen.png'
export const prashant = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940123/Hipbar_Website/Prasanth.png'
export const prasanna = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940118/Hipbar_Website/Prasanna.png'
export const aprup = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940048/Hipbar_Website/Aprup.png'
export const security = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940128/Hipbar_Website/Security.svg'
export const scalability = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940129/Hipbar_Website/Scalability.svg'
export const indiaStack = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940068/Hipbar_Website/India_stack.svg'
export const compliance = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940056/Hipbar_Website/Compliance.svg'
export const technology = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557942152/Hipbar_Website/technology.jpg'
export const resources = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557942298/Hipbar_Website/Resources.jpg'
export const business = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557942317/Hipbar_Website/business.jpg'
export const career = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557942378/Hipbar_Website/Career.jpg'
export const contact = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557942390/Hipbar_Website/Contact.jpg'
export const responsible = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940125/Hipbar_Website/Responsible.svg'
export const discover = 'https://res.cloudinary.com/www-hipbar-com/image/upload/v1599049022/HipBar_Web_Resources/hipbar.com/2020/A/Business_Discover_Bottles.svg'
export const cashless = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940046/Hipbar_Website/100_Cashless.svg'
export const bira = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940049/Hipbar_Website/Bira.png'
export const brownForman = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940051/Hipbar_Website/BrownForman.png'
export const bacardi = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940051/Hipbar_Website/BrownForman.png'
export const abInBev = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940047/Hipbar_Website/ABInBev.png'
export const diageo = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940053/Hipbar_Website/Diageo.png'
export const groverZampa = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940113/Hipbar_Website/Grover_Zampa_Logo_Grey.png'
export const firstPost = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940060/Hipbar_Website/FirstPost.png'
export const drinksReport = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940055/Hipbar_Website/Drinks_Report.png'
export const businessLine = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940051/Hipbar_Website/BusinessLine.png'
export const mediaNama = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940106/Hipbar_Website/MediaNamma.png'
export const techStory = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940150/Hipbar_Website/TechStory.png'
export const yourStory = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940146/Hipbar_Website/YourStory.png'
export const blog = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940435/Hipbar_Website/Blog.jpg'
export const twitterWhite = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940152/Hipbar_Website/twitter-logo.svg'
export const instagramWhite = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940094/Hipbar_Website/instagram-white.svg'
export const facebookWhite = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940056/Hipbar_Website/facebook-logo.svg'
export const facebookBlack = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940059/Hipbar_Website/facebook-logo2.svg'
export const instagramBlack = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940074/Hipbar_Website/instagram.svg'
export const twitterBlack = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940145/Hipbar_Website/twitter-logo2.svg'
export const ageGate = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940134/Hipbar_Website/Age-Gate-Banner.jpg'
export const caseStudyChennai = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557944821/Hipbar_Website/MRP.jpg'
export const caseStudyBengaluru = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557940061/Hipbar_Website/Delivery.png'
export const menu = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557944302/Hipbar_Website/menu-button.svg'
export const cross = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557944302/Hipbar_Website/cross.svg'
export const solution = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557944772/Hipbar_Website/Solution.svg'
export const problem = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557944772/Hipbar_Website/Problem.svg'
export const result = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557944772/Hipbar_Website/Result.svg'
export const mrpHero = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557944824/Hipbar_Website/MRP_hero.jpg'
export const deliveryHero = 'https://res.cloudinary.com/www-hipbar-com/image/upload/q_auto:good/v1557944846/Hipbar_Website/Delivery_hero.jpg'
