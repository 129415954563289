import React from 'react'
import { hydrate } from 'react-dom'
import { createBrowserHistory as createHistory } from "history"
import { Router } from 'react-router'
import {
  Route,
  Switch
} from 'react-router-dom'

import Navbar from './../components/navbar'
import Footer from './../components/footer'
import Home from './home'
//import About from './about'
import Technology from './technology'
import Business from './business'
import ContactUs from './contact'
import Careers from './careers'
import Welcome from './welcome'
import Resources from './resources'
import ChennaiCaseStudy from './case-study/chennai'
import BangloreCaseStudy from './case-study/banglore'
import NotFound from './not-found'

const history = createHistory()

class App extends React.Component {
  constructor () {
    super()
    this.state = {
      currentRoute: window.location.pathname.split('/')[1]
    }
  }

  checkAge () {
    if (parseInt(localStorage.getItem("age")) < 21 || !localStorage.getItem("age")) {
      location.href = '/'
    }
  }

  componentDidMount () {
    if (this.state.currentRoute.length) {
      this.checkAge()
    }
    history.listen((loction) => {
      const newRoute = location.pathname.split('/')[1]
      ga('set', 'page', location.pathname)
      ga('send', 'pageview')
      this.setState({ currentRoute: newRoute })
    })
  }

  render () {
    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Welcome currentRoute={this.state.currentRoute} />
            )}
          />
          <Route
            exact
            path="/home"
            render={() => (
              <React.Fragment>
                <Home history={history} currentRoute={this.state.currentRoute} />
              </React.Fragment>
            )}
          />
	  <Route
            exact
            path="/technology"
            render={() => (
              <React.Fragment>

                <Technology history={history} currentRoute={this.state.currentRoute} />

              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/business"
            render={() => (
              <React.Fragment>

                <Business history={history} currentRoute={this.state.currentRoute} />

              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/contact"
            render={() => (
              <React.Fragment>

                <ContactUs history={history} currentRoute={this.state.currentRoute} />

              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/careers"
            render={() => (
              <React.Fragment>

                <Careers history={history} currentRoute={this.state.currentRoute} />

              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/resources"
            render={() => (
              <React.Fragment>

                <Resources history={history} currentRoute={this.state.currentRoute} />

              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/case-study/chennai"
            render={() => (
              <React.Fragment>

                <ChennaiCaseStudy history={history} currentRoute={this.state.currentRoute} />

              </React.Fragment>
            )}
          />
          <Route
            exact
            path="/case-study/banglore"
            render={() => (
              <React.Fragment>
                <BangloreCaseStudy history={history} currentRoute={this.state.currentRoute} />
              </React.Fragment>
            )}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

hydrate(<App />, document.getElementById('root'))

export default App
