import React from 'react'
import './../../sass/careers.scss'
import HeroContainer from './../../components/hero-container'
import Navbar from './../../components/navbar'
import { career } from './../../images'

// const jobItems = [
//   {
//     title: `Copy Writer`,
//     summary: `We are looking for creative people who have a way with language. As a copywriter, you’ll be responsible for creating and executing social media campaigns, with a focus on Facebook and Twitter. This will involve tasks such as:`,
//     description: `
//     Curating all published social media content
//     Developing and expanding community and influencer outreach efforts
//     Monitoring online feedback and responding to each feedback
//     Compiling reports for management showing results
//     `
//   },
//   {
//     title: `DevOps Engineer`,
//     summary: `As a devops engineer, you will be expected to jump in and develop strong solutions that can scale. You will have an opportunity to make an immediate impact that improves the quality of our infrastructure.`,
//     description: `
//     Prior experience with scalable architecture
//     At least 2 years of work experience in a Linux-based DevOps role
//     Strong problem-solving and analytical skills
//     Experience with high-performance systems
//     A strong desire and the ability to learn quickly
//     Experience with configuration management, monitoring, and automation tools
//     Experience working with Docker and Kubernetes
//     `
//   },
//   {
//     title: `iOS Developer`,
//     summary: `
//     You will be primarily responsible for driving product development with a focus on continuous improvement of performance and user experience.
//     `,
//     description: `
//     At least 2 years of software engineering and product delivery experience
//     Experience with Swift
//     Strong problem-solving and analytical skills
//     A strong desire and the ability to learn quickly
//     `
//   },
//   {
//     title: `Software Development Engineer`,
//     summary: `
//       As a software development engineer, you will be responsible for driving innovation in full-stack product development. This will include monitoring & improving performance, scalability, and maintainability.
//     `,
//     description: `
//     Prior experience with scalable Architecture
//     At least 3 years of software engineering and product delivery experience
//     Good command over Data Structures and Algorithms
//     Strong problem-solving and analytical skills
//     Experience with web technologies, Go, Python, Linux, Postgres, Elasticsearch, Redis
//     A strong desire and the ability to learn quickly
//     `
//   },
//   {
//     title: `Technical Writer`,
//     summary: `
//       As a technical writer, you will be expected to jump in and document our existing product. You will have an opportunity to make an immediate impact by improving our present product offering.
//     `,
//     description: `
//     Prior experience documenting a product
//     Familiarity with cloud technologies
//     Experience documenting APIs is a plus
//     `
//   }
// ]


class Careers extends React.Component {
  render () {
    return (
      <div id="careers">
        <Navbar currentRoute={this.props.currentRoute} history={this.props.history} />
        <HeroContainer bgImage={career}>
          <div className="hero-content">
            <h1>Work with us</h1>
          </div>
        </HeroContainer>

        <div className="content">
          <div className="container about">
            <h2>
              We’re Hiring! Your skills are valued
            </h2>
            <p>
              HipBar is looking for talented individuals from design<br />
              and tech domains to join their team that excels in mobile application development.
              <br /><br />
              To  apply, email your portfolio/updated CV to <a href="mailto:careers@hipbar.com">careers@hipbar.com</a>
            </p>
          </div>

          {/* <div className="container openings"> */}
            {/* <h2>
              Current Openings
            </h2> */}
            {/* <p>
              We're hiring for a number of different positions
            </p> */}

            {
              // jobItems.map((item, i) => (
              //   <div key={i} className="job-item">
              //     <p className="job-title">{item.title}</p>
              //     <p className="job-summary">{item.summary}</p>
              //     <ul>
              //       {
              //         item.description.trim().split('\n').map((x, i) => (
              //           <li key={i}>{x}</li>
              //         ))
              //       }
              //     </ul>
              //     {/* <a>Apply Now</a> */}
              //     {jobItems.length - 1 !== i && <hr />}
              //   </div>
              // ))
            }
          {/* </div> */}
        </div>
      </div>
    )
  }
}


export default Careers
