import React from 'react'
import './hero-container.scss'

const HeroContainer = ({children, bgImage }) => (
  <div style={{ backgroundImage: `url(${bgImage})` }} className="hero-container">
    { children }
  </div>
)

export default HeroContainer
