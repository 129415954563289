import React from 'react'
import './../../sass/contact.scss'
import HeroContainer from './../../components/hero-container'
import Navbar from './../../components/navbar'
import {
  contact,
  facebookBlack,
  instagramBlack,
  twitterBlack
} from './../../images'


class ContactUs extends React.Component {
  render () {
    return (
      <div id="contact">
        <Navbar currentRoute={this.props.currentRoute} history={this.props.history} />
        <HeroContainer bgImage={contact}>
          <div className="hero-content">
            <h1>Contact Us</h1>
          </div>
        </HeroContainer>

        <div className="content">
          <div className="container about">
            <h2>
              Get in touch and let us know
            how we can help.
            </h2>
            <p>
              For general inquiries, please email us at <a href="mailto:support@hipbar.com">support@hipbar.com</a> or <a href="mailto:suppport@dreampurse.in">suppport@dreampurse.in</a>.
            </p>
          </div>

          <div className="container address">
            <div className="col">
              <p>Corporate Address</p>
              <p>
                Dreampurse Technologies Private Limited,<br />
                (Formerly Hip Bar Private Limited) c/o CRED,<br />
                No. 769 and 770, 100 Feet Road, 12th Main,<br />
                HAL 2nd Stage, Indiranagar, Bangalore 560038.
              </p>
            </div>

            <div className="col">
              <p>Follow us on</p>
              <div>
                <a target="_blank" href='https://facebook.com/hipbarapp'><img src={facebookBlack} /></a>
                <a target="_blank" href='https://twitter.com/hipbarapp'><img src={twitterBlack} /></a>
                <a target="_blank" href='https://instagram.com/hipbarapp'><img src={instagramBlack} /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default ContactUs
