import React from 'react'
import './../../sass/business.scss'
import HeroContainer from './../../components/hero-container'
import Navbar from './../../components/navbar'
import {
  business,
  hipbarNewApp,
  hipbarLogo,
  googlePlayBtn,
  appStoreBtn,
  cashless,
  discover,
  responsible,
  abInBev,
  groverZampa,
  bacardi,
  diageo,
  brownForman,
  bira
} from './../../images'

class Business extends React.Component {
  render() {
    return (
      <div id="business">
        <Navbar currentRoute={this.props.currentRoute} history={this.props.history} />
        <HeroContainer bgImage={business}>
          <div className="hero-content">
            <h1>Business</h1>
          </div>
        </HeroContainer>

        <div className="content">
          <div className="container about">
            <p>
              HipBar is more than just an app in the beverage alcohol industry.
It is an ecosystem that seamlessly connects the stakeholders of the industry towards the ultimate goal of making India drink better and more importantly drink wiser.
            </p>
          </div>
        </div>

        {/* <h2>Our Apps</h2> */}

        <div className="container apps">
          <div className="left">
            <img src={hipbarNewApp} />
          </div>
          <div className="right">
            <img className="logo" src={hipbarLogo} />
            <h1 style={{ color: '#e97c07' }}>HipBar</h1>
            <p>The drinks delivery app</p>
            {/* <p>Locate stores, check drink availability &amp; pay securely</p> */}

            <div className="app-btns">
              <a target="_blank" href="https://hipbar.onelink.me/X8lF/HBWebsiteBadgeSep2"><img src={googlePlayBtn} /></a>
              <a target="_blank" href="https://hipbar.onelink.me/0DEk/HBWebsiteBadgeSep2"><img src={appStoreBtn} /></a>
            </div>

            <p>
            Get your favourite drinks home delivered in a flash! You can also check what's available at each 
            store and pay for your order securely. 100% age-verified deliveries only!           
           </p>
          </div>
        </div>

        <div className="container benefits">
          <div className="benefit-item">
            <div className="benefit-item-img">
              <img src={cashless} />
            </div>
            <p>100% Cashless</p>
            <p>
              No cash. No problem. Get your drink effortlessly in just a few swipes. It's safe & secure!
            </p>
          </div>

          <div className="benefit-item">
            <div className="benefit-item-img">
              <img src={discover} />
            </div>
            <p>Discover</p>
            <p>
              Access a wide range of drinks from retailers in your city. Discover new brands & upgrade your preferences.
            </p>
          </div>

          <div className="benefit-item">
            <div className="benefit-item-img">
              <img src={responsible} />
            </div>
            <p>Responsible</p>
            <p>
              We are here to make a difference and help India drink responsibly. We are 100% compliant with extant legislations.
            </p>
          </div>
        </div>

        {/* <div className="container hipbar-wallet">
          <h2>HipBar Wallet</h2>
          <p>
            The RBI-approved HipBar wallet allows customers to load money and make payments. Relying on highly secure tech infrastructure, the HipBar wallet offers customers a safe, secure & fast way to pay for their drinks at retail stores.
          </p>
        </div> */}

        <div className="container brand-partners">
          <h2>Brand Partners</h2>

          <p>
            We have partnered with some of the top brands in the beverage alcohol industry in our endeavor to help India drink better and drink wiser.
          </p>
          <div className="brand-partners-items">
            {/* <img src={abInBev} /> */}
            {/* <img src={groverZampa} /> */}
            {/* <img src={diageo} /> */}
            {/* <img src={brownForman} /> */}
            {/* <img src={bacardi} /> */}
            {/* <img src={bira} /> */}
          </div>
          <ul>
            <li>We help the brands connect with customers and build better relationships</li>
            <li>We provide actionable data for responsible promotions</li>
            <li>We have created a responsible ecosystem that prevents underage drinking</li>
            <li>Our app provides a hassle-free digital experience</li>
          </ul>
        </div>

        <div className="container retail-partners">
          <h2>Retail Partners</h2>

          <p style={{ color: '#fff', textAlign: 'center', marginBottom: '40px' }}>
            HipBar has transformed the way the retail store connects with the customer.
          </p>
          {/* <div className="col">
            <p>Chennai</p>
            <p>
              <a target="_blank" href="/chennai-retailers">Click here</a>&nbsp;to see the list
              of retail partners
            </p>
          </div>

          <div className="col">
            <p>Bengaluru</p>
            <p>
              <a target="_blank" href="/banglore-retailers">Click here</a>&nbsp;to see the list
              of retail partners
            </p>
          </div> */}

          <div style={{ textAlign: 'center', marginTop: '40px', lineHeight: '1.7' }}>
            <ul style={{ color: '#fff', display: 'inline-block', textAlign: 'left' }}>
              <li>A hassle-free digital experience throws open the doors to newer and wiser customers</li>
              <li>The platform allows you to communicate with brands about on-ground feedback regarding products</li>
              <li>We provide actionable data for better understanding of trends to enable better merchandising and stocking</li>
            </ul>
          </div>
        </div>

      </div>
    )
  }
}


export default Business
