import React from 'react'
import './../../sass/case-study.scss'
import HeroContainer from './../../components/hero-container'
import Navbar from './../../components/navbar'
import {
  mrpHero,
  problem,
  result,
  solution
} from './../../images'


class ChennaiCaseStudy extends React.Component {
  render () {
    return (
      <div id="case-study">
        <Navbar currentRoute={this.props.currentRoute} history={this.props.history} />
        <HeroContainer bgImage={mrpHero}>
          <div className="hero-content">
            <h1>MRP issue in Chennai</h1>
            <p><span>Disclaimer: </span>HipBar Pay is currently non-operational. Currently, the HipBar app can be used to pay at MRP at TASMAC mall shops across Tamil Nadu.</p>
          </div>
        </HeroContainer>

        <div className="content">
          <div className="container problems">
            <img src={problem} />
            <h2>Problems</h2>
            <p>
              TASMAC outlets across Tamil Nadu charge above MRP. The extra amount
charged (illegally) varies from Rs. 10 to Rs. 100 depending on the product. Customers are harassed and abused if they question this "norm", leading to dissatisfaction.
            </p>
          </div>

          <div className="container solution">
            <img src={solution} />
            <h2>Solution</h2>
            <h3>
              HipBar Pay's system of paying at MRP
            </h3>
            <div className="case-study-content">
              <div className="case-study-content-item">
                <p>Why HipBar Pay?</p>
                <p>
                  It’s fast, It’s convenient, It’s secure
                  MRP only, You don’t need to carry cash!
                  Zero service fee
                </p>
              </div>

              <div className="case-study-content-item">
                <p>How does it work?</p>
                <p>
                  1)Download HipBar Pay

                  2) Add Money

                  3) Pay through the app

                  4) Collect your drink
                </p>
              </div>

              <div className="case-study-content-item">
                <p>Where is it available?</p>
                <p>
                  <a href="/chennai-retailers">Click here</a> to see the list of retail outlets, Chennai<br /><br />

                  If you encounter any violation, including abusive behaviour,
                  denial of service, overcharging etc, you can do any of the following:<br /><br />

                  Use the “Report an issue” feature in the app
                  Write to <span>support@hipbar.com</span><br />
                  Call TASMAC support through the app
                  Please report any issues you face and help us build a safe,
                  fair and transparent ecosystem.<br /><br />

                  We’re constantly expanding our network.
                  Download the app and follow us on social media
                  to know about new outlets added, updates etc.
                </p>
              </div>
            </div>
          </div>

          <div className="container result">
            <img src={result} />
            <p>
              12,332 app downloads and 4,110 transactions in 4 months.
            </p>
          </div>
        </div>
      </div>
    )
  }
}


export default ChennaiCaseStudy
