import React from 'react'
import './not-found.scss'

const NotFound = () => (
  <div id="not-found">
    <div className="content">
      <h1>404</h1>
      <p>Sorry! Page not found.</p>
    </div>
  </div>
)

export default NotFound
