import React from 'react'
import './../../sass/case-study.scss'
import HeroContainer from './../../components/hero-container'
import Navbar from './../../components/navbar'
import {
  deliveryHero,
  problem,
  result,
  solution
} from './../../images'

class BangloreCaseStudy extends React.Component {
  render () {
    return (
      <div id="case-study">
        <Navbar currentRoute={this.props.currentRoute} history={this.props.history} />
        <HeroContainer bgImage={deliveryHero}>
          <div className="hero-content">
            <h1>HipBar Delivery in Bengaluru</h1>
            <p><span>Disclaimer: </span>The above case study refers to the delivery pilot conducted by HipBar from Sep 2017 - Nov 2018. HipBar does not currently home deliver.</p>
          </div>
        </HeroContainer>

        <div className="content">
          <div className="container problems">
            <img src={problem} />
            <h2>Problems</h2>
            <p>
              Needs of the digital-age customers were not met.
            </p>
          </div>

          <div className="container solution">
            <img src={solution} />
            <h2>Solution</h2>
            <h3>
              The HipBar Delivery app puts their store on the map, connects
them with digital savvy customers, increases sales & revenue.
In addition to delivery, customers can also reserve a drink and pick it up
from the store at their convenience.
            </h3>
            <div className="case-study-content">
              <div className="case-study-content-item">
                <p>Where do we deliver?</p>
                <p>
                  At the moment we deliver to select areas in Bengaluru. We plan to expand to other areas in Bengaluru in the next few weeks and to other cities real soon.
                </p>
              </div>


              <div className="case-study-content-item">
                <p>Here’s where we deliver:</p>
                <p>
                  Akshayanagar, Basavanagudi, Bellandur, Bommanahalli, Banashankari, Btm Layout Central Business District, Electronic City, Frazer Town, Hebbal, HRBR Layout HSR Layout, Indiranagar, Jayanagar, JP Nagar,
                  Kaggadaspura, Kengeri Koramangala, Mathikere, Nagarbhavi, Peenya, Rajaji Nagar, Rajarajeshwari Nagar, RT Nagar, Sahakarnagar, Thanisandra, Uttarahalli, Kadugodi, Varthur.

                  Kindly note that delivery is contingent on your location falling within
                  a 3-km radius from a HipBar-affiliated retail store. If you live in the areas mentioned above and still unable to order, don’t worry! We are continually expanding our network and tying up with new retailers
                  every day. We will be able to serve you very soon.
                </p>
              </div>


              <div className="case-study-content-item">
                <p>When do we deliver?</p>
                <p>
                  We deliver from 2 PM to 10 PM on weekdays & from 11 AM — 10 PM on weekends. (Might be slightly revised in the event of specific orders from govt. authorities or for internal reasons)

                  What documents do I need to present at the time of delivery?

                  HipBar services can only be availed by individuals who are above the legal drinking age in their respective city/state. Hence a valid government ID for the purpose of age identification is mandatory.

                  You may provide any one of the following government-recognised original documents for customer verification:

                  Aadhar Card, Driving License, Passport, PAN Card, Voter ID
                  Failure to provide any government recognised ID proof will result in the order being cancelled/ rejected by the delivery agent.

                </p>
              </div>

            </div>
          </div>

          <div className="container result">
            <img src={result} />
            <p>
              We're now in 29 areas across Bengaluru and have tied up with 52 stores (increasing to more soon) Retailers are happy with the results and supportive of our initiatives.
            </p>
          </div>
        </div>
      </div>
    )
  }
}


export default BangloreCaseStudy
